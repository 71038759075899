import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";
import { differenceInDays, formatDistance, format } from "date-fns";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonWithUnderline from "../components/Button/ButtonWithUnderline";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    posts: allSanityPodcast(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          slug {
            current
          }
          excerpt
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
    background: file(
      relativePath: { eq: "repeating/CTA/Background Mountain.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes =
    data &&
    data.posts &&
    mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);

  return (
    <Layout>
      <SearchEngineOptimization
        title="JBP Legal Podcast"
        description="Jorgensen, Brownell & Pepin, P.C. offers a podcast, Legal Ease, to address your most pressing legal concerns. Learn more and listen here!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative pt-16 md:pt-28 pb-14 md:pb-22 text-center md:text-left">
        <div className="absolute top-0 left-0 w-full h-full">
          <GatsbyImage
            image={data.background.childImageSharp.gatsbyImageData}
            className="h-full"
          />
        </div>
        <div className="container relative ">
          <h1 className="mb-0">JBP Legal Podcast</h1>
        </div>
      </section>

      <section className="pt-16 md:pt-24 pb-20 md:pb-32">
        <div className="container">
          <div className="max-w-4xl">
            {postNodes.length > 0 &&
              postNodes.map((node) => (
                <div key={node.id}>
                  <div className="group pb-12 border-b border-gray-200 mb-10">
                    <AniLink
                      fade
                      to={`/podcast/${node.slug.current}`}
                      className="no-underline"
                    >
                      <div className="flex flex-wrap md:flex-nowrap items-center mb-3">
                        {node.publishedAt && (
                          <div className="font-display text-sm text-gray-300 font-bold tracking-wide uppercase">
                            {differenceInDays(
                              new Date(node.publishedAt),
                              new Date()
                            ) > 3
                              ? formatDistance(
                                  new Date(node.publishedAt),
                                  new Date()
                                )
                              : format(
                                  new Date(node.publishedAt),
                                  "MMMM d, yyyy"
                                )}
                          </div>
                        )}
                      </div>
                      <div className="heading-three mb-3.5">{node.title}</div>
                      {node.excerpt && <p>{node.excerpt}</p>}
                      <ButtonWithUnderline text="Listen to Podcast" />
                    </AniLink>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
